import * as React from 'react';
import { Trans } from '@elevio/kb-kit/lib/components/trans';
import { useTranslation } from '@elevio/kb-kit/lib/hooks';
import * as Categories from '@elevio/kb-kit/lib/components/categories';
import PageLayout from '../components/layouts/Page';
import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';
import ChatButtons from '../components/ChatButtons';
import {
  MainSection,
  GridContainer,
  GridItem,
} from '../components/layouts/Main';
import CategoryCard from '../components/CategoryCard';
import SearchInput from '../components/searchInput/SearchInput';
const HomePage = () => {
  const { t } = useTranslation();
  return (
    <PageLayout header={<Header />} footer={<Footer />}>
      <div className="banner">
        <div className="container">
          <h1>Saxo Kundeservice</h1>
          <p>Få hjælp til din ordre gennem vores artikler, eller ved at kontakte os med mail, chat eller via telefon<br /></p>
        </div>
      </div>

      <div className="container container--vertical-padding">
        <div className="contact-row">
          <div className="rounded-card">
            <h3>Kontakt Information</h3>
            <p>Telefon: (+45) 3815 0510<br />E-mail: info@saxo.com<br />Adr.: Østerbrogade 200, 2100 København.</p>
          </div>

          <div className="rounded-card">
            <h3>Åbningstider</h3>
            <p>Mandag-Torsdag: 10-17<br />Fredag: 10-16<br />Weekend & Helligdage: Lukket</p>
          </div>

          <ChatButtons />
        </div>

        {/* <GridContainer>
          <Categories.Loop>
            <GridItem>
              <CategoryCard data-testid="category-card" />
            </GridItem>
          </Categories.Loop>
        </GridContainer> */}

        <div className="cards-grid">
          <a className="category-card icon icon-saxo-s" href="/da/categories/2-saxo-medlemskab">
            <h2>Saxo Medlemskab</h2>
            <p>Saxo.com tilbyder et attraktivt medlemskab. Læs mere her</p>
          </a>

          <a className="category-card icon icon-profile" href="/da/categories/6-saxo-profil">
            <h2>Saxo Profil</h2>
            <p>Se hvordan du ændrer dit kodeordet eller ændrer dine oplysninger</p>
          </a>

          <a className="category-card icon icon-app" href="/da/categories/3-saxo-app">
            <h2>Saxo App</h2>
            <p>Læs om brugen af Saxos app, til både købte samt streamede bøger</p>
          </a>

          <a className="category-card icon icon-phone" href="/da/categories/5-e-boger">
            <h2>E-bøger</h2>
            <p>Hvordan du læser dine e-bøger, og information om disse</p>
          </a>

          <a className="category-card icon icon-headphones" href="/da/categories/4-lydboger">
            <h2>Lydbøger</h2>
            <p>Information om lydbøger og hjælp til disse</p>
          </a>

          <a className="category-card icon icon-basket" href="/da/categories/7-kob-bestilling">
            <h2>Køb og Bestilling</h2>
            <p>Få et overblik over dine nuværende og tidligere bestillinger</p>
          </a>

          <a className="category-card icon icon-credit-card" href="/da/categories/8-betaling-gavekort">
            <h2>Betaling og Gavekort</h2>
            <p>Praktiske informationer om betaling og køb af gavekort hos Saxo.com</p>
          </a>

          <a className="category-card icon icon-delivery" href="/da/categories/9-levering">
            <h2>Levering</h2>
            <p>Praktisk information vedrørende levering af din ordre</p>
          </a>

          <a className="category-card icon icon-back" href="/da/categories/10-retur-reklamation">
            <h2>Retur og Reklamation</h2>
            <p>Læs mere om vores retur- og reklamationsmuligheder</p>
          </a>

          <a className="category-card icon icon-profession" href="/da/categories/13-erhverv">
            <h2>Erhverv</h2>
            <p>Spørgsmål til Faktura, EAN eller din virksomhedsprofil</p>
          </a>

          <a className="category-card icon icon-terms" href="/da/categories/11-vilkar-datapolitik">
            <h2 className="card-title">Vilkår og datapolitik</h2>
            <p className="card-info">Læs om vilkår og betingelser ved handel med Saxo.com</p>
          </a>

          <a className="category-card icon icon-diverse" href="/da/categories/12-diverse">
            <h2>Diverse</h2>
            <p>Her finder du svar på de spørgsmål, der ikke falder indenfor de andre kategorier</p>
          </a>
        </div>
      </div>
    </PageLayout>
  );
};
export default HomePage;
